const AccelerateScreeningIcon = ({color="#FEFEFE", ...params})=>{
    return (
        <svg width="52" height="52" {...params} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9469 28.6314L39.1574 18.1051M31.2626 33.8946C31.2626 36.8013 28.9062 39.1577 25.9995 39.1577C23.0927 39.1577 20.7363 36.8013 20.7363 33.8946C20.7363 30.9878 23.0927 28.6314 25.9995 28.6314C28.9062 28.6314 31.2626 30.9878 31.2626 33.8946Z" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M10.21 25.9997C10.21 17.2794 17.2791 10.2102 25.9994 10.2102C28.8754 10.2102 31.5718 10.9791 33.8942 12.3226" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 26C1 14.2149 1 8.32233 4.66117 4.66117C8.32233 1 14.2149 1 26 1C37.7851 1 43.6777 1 47.3388 4.66117C51 8.32233 51 14.2149 51 26C51 37.7851 51 43.6777 47.3388 47.3388C43.6777 51 37.7851 51 26 51C14.2149 51 8.32233 51 4.66117 47.3388C1 43.6777 1 37.7851 1 26Z" stroke={color} strokeWidth="2"/>
        </svg>

    )
}

export default AccelerateScreeningIcon;