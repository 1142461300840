const ImproveCandidateMatchingIcon = ({color="#1C1C1C", ...params}) => {
    return (
        <svg width="54" height="54" {...params} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.25 52H13.4772C9.61373 52 6.54077 50.12 3.78165 47.4914C-1.86659 42.1103 7.40701 37.81 10.9439 35.704C16.5675 32.3555 23.2163 31.2773 29.5 32.4696" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M35.75 13.25C35.75 19.4632 30.7132 24.5 24.5 24.5C18.2868 24.5 13.25 19.4632 13.25 13.25C13.25 7.0368 18.2868 2 24.5 2C30.7132 2 35.75 7.0368 35.75 13.25Z" stroke={color} strokeWidth="2.5"/>
            <path d="M43.7276 33.4442L45.4874 36.9929C45.7274 37.4869 46.3673 37.9607 46.9073 38.0514L50.0969 38.5858C52.1367 38.9285 52.6167 40.4206 51.1468 41.8925L48.6671 44.3927C48.2471 44.8161 48.0172 45.6327 48.1471 46.2175L48.8571 49.3125C49.417 51.7623 48.1272 52.71 45.9774 51.4296L42.9877 49.6452C42.4478 49.3226 41.5579 49.3226 41.0079 49.6452L38.0183 51.4296C35.8785 52.71 34.5786 51.7522 35.1386 49.3125L35.8485 46.2175C35.9785 45.6327 35.7485 44.8161 35.3286 44.3927L32.8488 41.8925C31.389 40.4206 31.8589 38.9285 33.8987 38.5858L37.0884 38.0514C37.6183 37.9607 38.2582 37.4869 38.4982 36.9929L40.258 33.4442C41.2179 31.5186 42.7777 31.5186 43.7276 33.4442Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ImproveCandidateMatchingIcon;