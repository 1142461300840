import { useEffect } from "react";
import SectionTitle from "../components/SectionTitle";
import "../styles/features-section.css";
import { useTheme } from "../contexts/ThemeContext";
import StarIcon from "../icons/StarIcon";
import ResumeParsingIcon from "../icons/ResumeParsingIcon";
import DecodeResumeIcon from "../icons/DecodeResumeIcon";
import ResumeAnalysisIcon from "../icons/ResumeAnalysisIcon";
import SystemIntegrationIcon from "../icons/SystemIntegrationIcon";
import HireIcon from "../icons/HireIcon";
import DataProtectionIcon from "../icons/DataProtectionIcon";
import RocketIcon from "../icons/RocketIcon";
import useResponsiveView from "../hooks/useResponsiveView";

const FeaturesContent = [
    {
        contentIcon : DecodeResumeIcon,
        contentTitle : "Resume Overview",
        content : "Quickly understand candidate strengths with concise, AI-driven resume summaries."
    },
    {
        contentIcon : ResumeAnalysisIcon,
        contentTitle : "Data Accuracy",
        content : "Ensure flawless data extraction for precise and reliable hiring outcomes."
    },
    {
        contentIcon : SystemIntegrationIcon,
        contentTitle : "System Integration",
        content : "Enjoy effortless integration with your HR systems for a seamless workflow."
    },
    {
        contentIcon : HireIcon,
        contentTitle : "Smart Hiring",
        content : "Enhance recruitment with AI-powered tools for faster, smarter hiring."
    },
    {
        contentIcon : DataProtectionIcon,
        contentTitle : "Data Protection",
        content : "Protect sensitive candidate information with industry-leading security protocols."
    },
    {
        contentIcon : RocketIcon,
        contentTitle : "Format Support",
        content : "Effortlessly process resumes in multiple file formats for complete flexibility."
    }
]

const FeaturesSection = ()=>{
    const {theme} = useTheme();
    const isMobile = useResponsiveView();
    return (
        <section className="features-section" id="features-section">
            <SectionTitle key={'features'} title={"Before You Ask"} />

            <div className="features-box">
                {
                    isMobile ? null : [1, 2].map(value=>{
                        return <StarIcon key={value} className={`features-star ${theme}`} style={{
                            left:(`calc(-25px + ${(5 + value * 30)}%)`)
                        }}/>
                    })
                }
                {
                    FeaturesContent.map((item, index)=>{
                        return (
                            <div key={index} className="features-item">
                                <div className="features-item-box">
                                    <div className="features-content">
                                        <item.contentIcon className='features-content-icon' />
                                        <h3 className="features-content-title">{item.contentTitle}</h3>
                                        <div className="features-content-text">{item.content}</div>
                                        {
                                            (isMobile && index < FeaturesContent.length-1) ? <div className={`features-hbar-mobile ${theme}`}></div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    isMobile ? null : <div className={`features-hbar ${theme}`} style={{
                    top:"50%"
                    }}></div>
                }
                {
                    isMobile ? null : [1, 2].map(value=>{
                        return <div key={value} className={`features-vbar ${theme}`} style={{
                            left:`calc(-1px + ${(5 + value * 30)}%)`
                        }}></div>
                    })
                }
            </div>
        </section>
    )
}

export default FeaturesSection;