import "../styles/section-title.css";

const SectionTitle = ({title}) => {
    return (
        <div className="section-title">
            <h2 className='section-title-text'>{title}</h2>
            <div className="section-hbar" />
        </div>
    )
}

export default SectionTitle;