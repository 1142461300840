const RocketIcon = ({color, ...params}) => {
    return (
        <svg width="72" height="72" {...params} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="72" height="72" rx="15" fill="#EA723C"/>
        <path d="M37.5 46C37.5 46 39.25 46 41 49.5C41 49.5 46.5588 40.75 51.5 39" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34 53H33.5227C27.8156 53 24.9621 53 22.9804 51.6038C22.4126 51.2038 21.9085 50.7293 21.4835 50.1949C20 48.3298 20 45.6441 20 40.2727V35.8182C20 30.6326 20 28.0399 20.8206 25.9691C22.1399 22.64 24.93 20.014 28.4671 18.7724C30.6673 18 33.4222 18 38.9318 18C42.0802 18 43.6544 18 44.9116 18.4414C46.9329 19.1509 48.5272 20.6514 49.2811 22.5537C49.75 23.7371 49.75 25.2186 49.75 28.1818V32" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 36L45 36" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 41.5H34.5" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 47L29.25 47" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.7004 29.0244C44.5025 28.5467 44.2124 28.1126 43.8468 27.747C43.4812 27.3814 43.0471 27.0914 42.5694 26.8935C42.0917 26.6956 41.5797 26.5937 41.0626 26.5938C40.5455 26.5938 40.0335 26.6956 39.5558 26.8935C39.078 27.0914 38.644 27.3814 38.2783 27.747C37.9127 28.1126 37.6227 28.5467 37.4248 29.0244M42.3751 23.3125C42.3751 24.0374 41.7874 24.625 41.0626 24.625C40.3377 24.625 39.7501 24.0374 39.7501 23.3125C39.7501 22.5876 40.3377 22 41.0626 22C41.7874 22 42.3751 22.5876 42.3751 23.3125Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 35.5C20 32.2783 22.6117 29.6667 25.8333 29.6667C26.9985 29.6667 28.3721 29.8708 29.5049 29.5673C30.5114 29.2976 31.2976 28.5114 31.5673 27.5049C31.8708 26.3721 31.6667 24.9985 31.6667 23.8333C31.6667 20.6117 34.2783 18 37.5 18" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default RocketIcon;