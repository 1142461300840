import { useTheme } from "../contexts/ThemeContext";
import AppLogo from "../icons/AppLogo";
import "../styles/titlebar.css";
import TitlebarItem from "../components/TitlebarItem";
import FillButton from "../components/FillButton";
import DarkThemeIcon from "../icons/DarkThemeIcon";
import LightThemeIcon from "../icons/LightThemeIcon";
import { useEffect, useState } from "react";
import HamburgerButton from "../components/HamburgerButton";
import { Collapse } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useResponsiveView from "../hooks/useResponsiveView";

const Titlebar = ()=>{
    const isMobile = useResponsiveView();
    const {theme, setTheme} = useTheme();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('#hero-section');
    const location = useLocation();
    const navigate = useNavigate();

    const handleSectionChangeMobile = (newVal) => {
        setActiveSection(newVal);
        setIsMenuOpen(false);
    }
    
    useEffect(()=>{
        if(location.pathname !== '/') return;
        const observer = new IntersectionObserver(
            (entries) => {
                let mostVisibleSection = null;
                let maxRatio = 0;

                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const ratio = entry.intersectionRatio;
                        if (ratio > maxRatio) {
                            maxRatio = ratio;
                            mostVisibleSection = entry.target.id;
                        }
                    }
                });
                setActiveSection(mostVisibleSection);
            },
            { threshold: [0.5] }
        );

        ['#hero-section', '#hiw-section', '#features-section', '#pricing-section'].forEach(section=>{
            if(document.querySelector(section)){
                observer.observe(document.querySelector(section));
            }
        })
        
        return ()=>{
            ['#hero-section', '#hiw-section', '#features-section', '#pricing-section'].forEach(section=>{
                if(document.querySelector(section)){
                    observer.unobserve(document.querySelector(section));
                }
            })
        }
    }, [location]);

    useEffect(()=>{
        if(location.pathname !== '/') return;
        if(!location?.state?.targetId) return;
        document.getElementById(location.state.targetId)?.scrollIntoView();
    }, [location])

    return (
        <div className={`titlebar-container ${theme}`}>
            <div className="titlebar-start">
                <AppLogo height="40" />
            </div>
            {
                isMobile ? 
                <>
                    <div className="titlebar-end">
                        {
                        theme === 'dark' ?
                            <DarkThemeIcon onClick={()=>setTheme('light')} className={`title-bar-theme-toggle-button ${theme}`} height={"3rem"} />
                            :
                            <LightThemeIcon onClick={()=>setTheme('dark')} className={`title-bar-theme-toggle-button ${theme}`} height={"3rem"} />
                        }
                        <HamburgerButton isOpen={isMenuOpen} onClick={()=>setIsMenuOpen(prev=>!prev)} />
                    </div>
                    <Collapse in={isMenuOpen} className="titlebar-mobile-menu">
                        <div className="titlebar-mobile-menu-inner">
                            <TitlebarItem targetId={"hero-section"} value={"Home"} activeSection={activeSection} setActiveSection={handleSectionChangeMobile} />
                            <TitlebarItem targetId={"hiw-section"} value={"How it Works"} activeSection={activeSection} setActiveSection={handleSectionChangeMobile} />
                            <TitlebarItem targetId={"features-section"} value={"Features"} activeSection={activeSection} setActiveSection={handleSectionChangeMobile} />
                            <TitlebarItem targetId={"pricing-section"} value={"Pricing"} activeSection={activeSection} setActiveSection={handleSectionChangeMobile} />
                            <FillButton className={`titlebar-button ${theme}`} value={"Contact Us"} 
                                onClick={()=>{
                                    if(location.pathname !== '/'){
                                        navigate('/', {state:{targetId : 'contact-us-section'}});
                                        return;
                                    }
                                    document.getElementById('contact-us-section').scrollIntoView({behavior:"smooth"});
                                    setIsMenuOpen(false);
                                }}
                            />
                        </div>
                    </Collapse>
                </>
                : 
                <>
                    <div className='titlebar-middle'>
                        <TitlebarItem targetId={"hero-section"} value={"Home"} activeSection={activeSection} setActiveSection={setActiveSection} />
                        <TitlebarItem targetId={"hiw-section"} value={"How it Works"} activeSection={activeSection} setActiveSection={setActiveSection} />
                        <TitlebarItem targetId={"features-section"} value={"Features"} activeSection={activeSection} />
                        <TitlebarItem targetId={"pricing-section"} value={"Pricing"} activeSection={activeSection} />
                    </div>
                    <div className="titlebar-end">
                        {
                            theme === 'dark' ?
                                <DarkThemeIcon onClick={()=>setTheme('light')} className={`title-bar-theme-toggle-button ${theme}`} height={"3rem"} />
                                :
                                <LightThemeIcon onClick={()=>setTheme('dark')} className={`title-bar-theme-toggle-button ${theme}`} height={"3rem"} />
                        }
                        <FillButton className={`titlebar-button ${theme}`} value={"Contact Us"} 
                            onClick={()=>{
                                if(location.pathname !== '/'){
                                    navigate('/', {state:{targetId : 'contact-us-section'}})
                                    return
                                }
                                document.getElementById('contact-us-section').scrollIntoView({behavior:"smooth"});
                            }}
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default Titlebar;