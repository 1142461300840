const SaveTimeAndResourcesIcon = ({color="#1C1C1C", ...params}) => {
    return (
        <svg width="54" height="44" {...params} viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="26.5094" cy="25.5294" rx="13.0719" ry="13.0719" stroke={color} strokeWidth="2.5"/>
        <path d="M26.5098 20.3007V25.5294L29.1241 28.1438" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.90234 17.6863L6.90235 2" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.8039 12.7844C11.8039 12.7844 8.19367 17.6863 6.90191 17.6863C5.61016 17.6864 2 12.7844 2 12.7844" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.0986 42.5229L47.0986 26.8366" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.0002 37.621C52.0002 37.621 48.39 42.5229 47.0982 42.5229C45.8064 42.523 42.1963 37.621 42.1963 37.621" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default SaveTimeAndResourcesIcon;