import { useEffect, useState } from "react";
import "../styles/grid-background.css";

const GridBackground = ()=>{
    const [boxes, setBoxes] = useState([]);
    useEffect(()=>{
        const updateBackground = ()=>{
            const boxSize = 0.25 * Math.min(window.innerHeight, window.innerWidth);
            const hBoxSize = window.innerHeight / Math.floor(window.innerHeight / boxSize);
            const vBoxSize = window.innerWidth / Math.floor(window.innerWidth / boxSize);
            const tmpBoxes = [];
            for(let i=1; i<(window.innerWidth / vBoxSize); i++){
                if(((window.innerWidth - i * vBoxSize)) < boxSize/2){
                    continue;
                }    
                tmpBoxes.push({
                    type : "hbox",
                    style : {left : (i * vBoxSize) + "px"}
                });
            }
            for(let i=1; i<(window.innerHeight / hBoxSize); i++){
                if(((window.innerHeight - i * hBoxSize)) < boxSize/2){
                    continue;
                }
                tmpBoxes.push({
                    type : "vbox",
                    style : {top : (i * hBoxSize) + "px"}
                });
            }
    
            document.documentElement.style.setProperty("--stripColor", "rgba(234, 114, 60, 0.2)");
            document.documentElement.style.setProperty('--hBoxSize', hBoxSize + "px");
            document.documentElement.style.setProperty('--vBoxSize', vBoxSize + "px");
            setBoxes(tmpBoxes);
        }

        
        window.addEventListener('resize', updateBackground);
        updateBackground();
        return () => window.removeEventListener('resize', updateBackground);
    }, [])
    return (
        <div className="grid-container">
            {
                boxes.map((item, idx)=>(
                    <div key={idx} className={item.type} style={item.style} />
                ))
            }
        </div>
    )
}

export default GridBackground;