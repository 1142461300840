const HireIcon = ({color, ...params}) => {
    return (
        <svg width="72" height="72" {...params} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="72" height="72" rx="15" fill="#EA723C"/>
        <path d="M15 36.9C15 34.5804 16.8804 32.7 19.2 32.7C22.6794 32.7 25.5 35.5206 25.5 39V47.4C25.5 50.8794 22.6794 53.7 19.2 53.7C16.8804 53.7 15 51.8196 15 49.5V36.9Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43.3054 27.0431L42.7461 28.8493C42.2879 30.3293 42.0588 31.0693 42.235 31.6538C42.3775 32.1266 42.6905 32.5341 43.1169 32.8023C43.644 33.1337 44.4414 33.1337 46.0362 33.1337H46.8846C52.2817 33.1337 54.9802 33.1337 56.2548 34.7313C56.4005 34.9139 56.5301 35.1081 56.6421 35.3117C57.6227 37.0935 56.5079 39.4938 54.2784 44.2943C52.2323 48.6997 51.2093 50.9023 49.3099 52.1988C49.126 52.3243 48.937 52.4427 48.7434 52.5537C46.7437 53.7 44.2659 53.7 39.3105 53.7H38.2357C32.232 53.7 29.2302 53.7 27.3651 51.8929C25.5 50.0858 25.5 47.1773 25.5 41.3602V39.3157C25.5 36.2587 25.5 34.7302 26.0425 33.3313C26.585 31.9323 27.6238 30.782 29.7015 28.4813L38.2934 18.9672C38.5089 18.7286 38.6167 18.6092 38.7117 18.5266C39.5984 17.7549 40.967 17.8418 41.7423 18.7189C41.8253 18.8129 41.9161 18.9448 42.0976 19.2086C42.3815 19.6213 42.5235 19.8276 42.6472 20.0321C43.7549 21.8622 44.0901 24.0362 43.5827 26.1C43.526 26.3305 43.4524 26.5682 43.3054 27.0431Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default HireIcon;