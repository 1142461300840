const DecodeResumeIcon = ({color, ...params}) => {
    return (
    <svg width="72" height="72" {...params} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="72" height="72" rx="15" fill="#EA723C"/>
        <path d="M23.5625 34.6875L44.5625 34.6875" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.5625 41.25H34.0625" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.5625 47.8125L28.8125 47.8125" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.2629 27.2744C44.065 26.7967 43.7749 26.3626 43.4093 25.997C43.0437 25.6314 42.6096 25.3414 42.1319 25.1435C41.6542 24.9456 41.1422 24.8437 40.6251 24.8438C40.108 24.8438 39.596 24.9456 39.1183 25.1435C38.6405 25.3414 38.2065 25.6314 37.8408 25.997C37.4752 26.3626 37.1852 26.7967 36.9873 27.2744M41.9376 21.5625C41.9376 22.2874 41.3499 22.875 40.6251 22.875C39.9002 22.875 39.3126 22.2874 39.3126 21.5625C39.3126 20.8376 39.9002 20.25 40.6251 20.25C41.3499 20.25 41.9376 20.8376 41.9376 21.5625Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M55.0625 57L50.8355 52.773M50.8355 52.773C51.061 52.5475 51.2706 52.3061 51.4625 52.0507C52.3102 50.9224 52.8125 49.5199 52.8125 48C52.8125 44.2721 49.7904 41.25 46.0625 41.25C42.3346 41.25 39.3125 44.2721 39.3125 48C39.3125 51.7279 42.3346 54.75 46.0625 54.75C47.9265 54.75 49.614 53.9945 50.8355 52.773Z" stroke="white" strokeWidth="1.8" strokeLinecap="round"/>
        <path d="M38 54.375H32.2131C25.7926 54.375 22.5823 54.375 20.3529 52.8043C19.7142 52.3542 19.1471 51.8205 18.6689 51.2193C17 49.1211 17 46.0996 17 40.0568V35.0455C17 29.2117 17 26.2948 17.9232 23.9652C19.4074 20.22 22.5462 17.2658 26.5255 15.8689C29.0008 15 32.0999 15 38.2983 15C41.8402 15 43.6112 15 45.0256 15.4965C47.2995 16.2947 49.0931 17.9828 49.9412 20.123C50.4688 21.4542 50.4688 23.121 50.4688 26.4545V38.625" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 34.6875C17 31.0631 19.9381 28.125 23.5625 28.125C24.8733 28.125 26.4186 28.3547 27.693 28.0132C28.8253 27.7098 29.7098 26.8253 30.0132 25.693C30.3547 24.4186 30.125 22.8733 30.125 21.5625C30.125 17.9381 33.0631 15 36.6875 15" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

export default DecodeResumeIcon;