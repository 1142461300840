import { Autocomplete, FormControl, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material";
import { color } from "framer-motion";

const CustomTextField = ({id, label, sx, style, InputProps, ...props}) => {
    return (
        <div
            style={{
                width:"100%"
            }}
        >
            <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} htmlFor={id}>{label}</label>
            <TextField
                {...props}
                style={{ width: "100%", margin:"0.5rem 0", ...style }}
                InputProps={{
                    style: { borderRadius: "10px", fontFamily: "Montserrat", ...InputProps },
                }}
                InputLabelProps={{
                    style:{
                        display:"none"
                    }
                }}
                margin="none"
                id={id}
                sx={{
                    "& .MuiOutlinedInput-root": {
                    color:"var(--text-color)",
                    transition:"background-color ease-in-out 300ms",
                    "& fieldset": {
                        borderColor: "gray",
                        transition:"border-color ease-in-out 300ms"
                    },
                    "&:hover fieldset": {
                        borderColor: "rgb(234, 114, 60)",
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "rgb(234, 114, 60)",
                    },
                    "&:hover": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                    "&.Mui-focused": {
                        backgroundColor: "rgb(234, 114, 60, 0.1)"
                    },
                    },
                    "& .MuiFormLabel-root": {
                    color: "gray",
                    "&.Mui-focused": {
                        color: "rgb(234, 114, 60)",
                    },
                    ...sx
                    },
                }}
            />
        </div>
    )
}

const Placeholder = styled("div")({
    color:"rgba(0, 0, 0, 0.4)"
})

const CustomSelect = ({id, label, placeholder, sx, options, ...props}) => {
    return (
        <div
            style={{
                width:"100%",
                display:"flex",
                flexDirection:"column"
            }}
        >
            <FormControl
                margin="none"
                sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    "& fieldset": {
                    borderColor: "gray",
                    },
                    "&:hover fieldset": {
                    borderColor: "rgb(234, 114, 60)",
                    },
                    "&.Mui-focused fieldset": {
                    borderColor: "rgb(234, 114, 60)",
                    },
                    "&:hover": {
                    backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                    "&.Mui-focused": {
                    backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                },
                "& .MuiSelect-select": {
                    "&:hover": {
                    backgroundColor: "rgb(234, 114, 60, 0.1)",
                    },
                },
                "& .MuiInputLabel-root": {
                    color: "gray",
                    "&.Mui-focused": {
                    color: "rgb(234, 114, 60)",
                    },
                },
                ...sx
                }}
            >
                <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} id={`label-${id}`} htmlFor={id}>{label}</label>
                <Select
                    id={id}
                    labelId={`label-${id}`}
                    defaultValue={""}
                    {...props}
                    displayEmpty
                    renderValue={value=> value !== "" ? value : <Placeholder>{placeholder}</Placeholder>} 
                >
                {options && options.length > 0 ? (
                    options.map((name, index) => (
                    <MenuItem
                        value={name}
                        key={index}
                        style={{ textTransform: "capitalize" }}
                    >
                        {name}
                    </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" disabled>
                        No roles available
                    </MenuItem>
                )}
                </Select>
            </FormControl>
        </div>
    )
}

const CustomAutoComplete = styled(({id, style, label, placeholder, required, ...params})=>
    <div
        style={{
            width:"100%"
        }}
    >
        <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} htmlFor={id}>{label}</label>
        <Autocomplete 
            renderInput={(params) => <TextField 
                id={id}
                placeholder={placeholder} {...params}
                required={required}
            />}
            style={{...style, margin:"0.5rem 0rem"}}
            {...params}
        />
    </div>
)({
    "& .MuiOutlinedInput-root": {
        color:"var(--text-color)",
        fontFamily:"Montserrat",
        borderRadius: "10px",
        "& fieldset": {
        borderColor: "gray",
        },
        "&:hover fieldset": {
        borderColor: "rgb(234, 114, 60)",
        },
        "&.Mui-focused fieldset": {
        borderColor: "rgb(234, 114, 60)",
        },
        "&:hover": {
        backgroundColor: "rgb(234, 114, 60, 0.1)",
        },
        "&.Mui-focused": {
        backgroundColor: "rgb(234, 114, 60, 0.1)",
        },
    },
    "& .MuiAutocomplete-inputRoot": {
        "&:hover": {
        backgroundColor: "rgb(234, 114, 60, 0.1)",
        },
    },
    "& .MuiInputLabel-root": {
        color: "gray",
        "&.Mui-focused": {
        color: "rgb(234, 114, 60)",
        },
    },
})

export { CustomTextField, CustomSelect, CustomAutoComplete };