import React, { useState } from "react";
import "../styles/faqs-section.css";
import SectionTitle from "../components/SectionTitle";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "../contexts/ThemeContext";

const FAQsItem = ({faq})=>{
    const [open, setOpen] = useState(false);
    const { theme } = useTheme();

    return (
        <div className={`faqs-item ${theme}`} onClick={()=>setOpen(prev=>!prev)}>
            <div className="faqs-question">
                {faq.question}
                <h3 className="faqs-button">{open ? "-" : "+"}</h3>
            </div>
            <Collapse in={open} >
                <div className="faqs-bar"></div>
                <div className="faqs-answer">{faq.answer}</div>
            </Collapse>
        </div>
    )
}

const FAQsSection = () => {
  const faqItems = [
    {
      question: "What is a resume parser and how does it work?",
      answer:
        "A resume parser is a tool or software that automatically extracts key details like contact info, experience, and skills from resumes using AI and ML, organizing the data for quick review and saving you time.",
    },
    {
      question: "What formats does the resume parser support?",
      answer:
        "Our resume parser supports PDF, DOC, DOCX, and TXT formats, ensuring compatibility with most resume submissions.",
    },
    {
      question: "How accurate is the resume parsing technology?",
      answer:
        "Our parser achieves over 95% accuracy in data extraction, minimizing errors and ensuring reliable candidate information.",
    },
    {
      question: "Can the resume parser integrate with my existing systems?",
      answer:
        "Yes, our resume parser seamlessly integrates with popular Applicant Tracking Systems (ATS) and HR software, fitting smoothly into your existing workflow.",
    },
    {
      question: "How does the resume parser improve the hiring process?",
      answer:
        "By automating resume screening, our parser speeds up your recruitment process, enhances accuracy, and helps you quickly identify top candidates.",
    },
    {
      question: "Is my data secure with your resume parser?",
      answer:
        "Absolutely. We prioritize data security with robust measures to protect your sensitive information and ensure confidentiality.",
    },
    {
      question: "How can I get started with the resume parser?",
      answer:
        "Just sign up for a free trial on our website, and we’ll guide you through the quick and easy setup process.",
    },
  ];

  return (
    <section className="faqs-section" id="faqs-section">
      <SectionTitle title="FAQs" />
      <div className="faqs-container">
          {faqItems.map((item, index) => (
            <FAQsItem key={index} faq={item} />
          ))}
      </div>
    </section>
  );
};

export default FAQsSection;
