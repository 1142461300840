import "../styles/fill-button.css";

const FillButton = ({value, className, inverse, ...params})=>{
    return (
        <button className={`${className} fill-button-container ${inverse ? "inverse" : ""}`} {...params}>
            <p>{value}</p>
            <div className={`fill-button-liquid ${inverse ? "inverse" : ""}`}></div>
        </button>
    )
}

export default FillButton;