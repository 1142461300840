import { useTheme } from "../contexts/ThemeContext";
import "../styles/hamburger-button.css";

const HamburgerButton = ({isOpen, onClick})=>{
    const { theme } = useTheme();
    return (
        <div className={`hamburger-button-container ${isOpen ? "open" : null }`} onClick={onClick}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>
    )
}

export default HamburgerButton;