import { useEffect, useRef } from "react";
import "../styles/privacy-policy.css";

const PrivacyPolicy = ()=>{
    const sectionRef = useRef();

    useEffect(()=>{
        if(!sectionRef) return;
        sectionRef.current.scrollIntoView({behavior:"smooth"});
    }, [])

    return (
        <section ref={sectionRef} className="privacy-policy-section">
            <h1>Privacy Policy</h1>
            <p>Effective Date: 28 Aug 2024</p>

            <p>Welcome to Scan2Hire. Your privacy is important to us. This Privacy Policy explains how Scan2Hire ("we," "our," or "us") collects, uses, discloses, and safeguards your information when you use our recruitment software and services. By accessing or using our services, you agree to the collection and use of information in accordance with this policy.</p>

            <h2>1. Information We Collect</h2>

            <h3>1.1 Personal Information</h3>
            <p>We collect personal information that identifies you directly when you interact with our services. This includes:</p>
            <ul>
                <li><strong>Account Information:</strong> When you create an account, we collect your full name, email address, phone number, job title, company name, and other details necessary for setting up and managing your account.</li>
                <li><strong>Payment Information:</strong> If you make purchases or transactions through our platform, we collect payment details such as credit card numbers, billing addresses, and other financial information necessary for processing payments securely.</li>
                <li><strong>Profile Information:</strong> We may collect additional information you provide when updating your profile, such as your resume, job preferences, and other relevant career information.</li>
            </ul>

            <h3>1.2 Usage Data</h3>
            <p>We automatically collect information about your interactions with our services, which may include:</p>
            <ul>
                <li><strong>Log Data:</strong> Information about your device and connection, including IP address, browser type, operating system, device model, and unique device identifiers. This data helps us understand how you access and use our services.</li>
                <li><strong>Usage Statistics:</strong> Information about your activity on our platform, such as the pages you visit, the features you use, the duration of your sessions, and the actions you take. This helps us analyze user behavior and improve our services.</li>
                <li><strong>Technical Data:</strong> Information about your internet service provider, geographical location, and the referring website or source that led you to our services.</li>
            </ul>

            <h3>1.3 Cookies and Tracking Technologies</h3>
            <p>We use cookies, web beacons, and similar tracking technologies to enhance your user experience and gather information about how you interact with our services. Cookies are small data files stored on your device that allow us to remember your preferences and provide a personalized experience. Tracking technologies may also include pixels or tags that help us measure the effectiveness of our marketing efforts and analyze user behavior.</p>
            <p>You can manage your cookie preferences through your browser settings. However, disabling cookies may affect certain functionalities of our services and limit your ability to use certain features.</p>

            <h2>2. How We Use Your Information</h2>

            <h3>2.1 Providing and Maintaining Services</h3>
            <ul>
                <li><strong>Service Delivery:</strong> To operate and maintain our recruitment software and ensure it functions smoothly. This includes processing your requests, managing your account, and providing customer support.</li>
                <li><strong>Feature Enhancement:</strong> To develop and improve our services based on user feedback, usage patterns, and emerging trends. We may also test new features and functionalities to enhance your experience.</li>
            </ul>

            <h3>2.2 Communication</h3>
            <ul>
                <li><strong>Account Management:</strong> To communicate with you about your account, including sending notifications about changes, updates, or maintenance related to our services.</li>
                <li><strong>Customer Support:</strong> To respond to your inquiries, address issues, and provide assistance with any problems you may encounter.</li>
                <li><strong>Marketing Communications:</strong> To send you information about new features, promotions, and other updates related to our services. You can opt out of marketing communications at any time by following the instructions provided in those communications or by adjusting your preferences in your account settings.</li>
            </ul>

            <h3>2.3 Personalization</h3>
            <ul>
                <li><strong>Customized Experience:</strong> To tailor content, recommendations, and features based on your preferences, behavior, and interactions with our services. This may include displaying relevant job opportunities, suggesting features, or providing personalized insights.</li>
                <li><strong>User Profiles:</strong> To create and manage user profiles that help match you with relevant opportunities and enhance the overall user experience.</li>
            </ul>

            <h3>2.4 Analytics and Research</h3>
            <ul>
                <li><strong>Usage Analysis:</strong> To analyze how our services are used, identify trends, and gather insights into user behavior. This helps us understand how our services are performing and where improvements are needed.</li>
                <li><strong>Research and Development:</strong> To conduct research on industry trends, user needs, and emerging technologies. This research informs the development of new features and enhancements to our services.</li>
            </ul>

            <h3>2.5 Legal and Regulatory Compliance</h3>
            <ul>
                <li><strong>Compliance:</strong> To comply with legal obligations, industry standards, and regulatory requirements. This includes responding to legal requests, enforcing our terms of service, and protecting our rights and interests.</li>
                <li><strong>Legal Claims:</strong> To defend against legal claims, protect our rights, and ensure compliance with applicable laws and regulations.</li>
            </ul>

            <h2>3. How We Share Your Information</h2>

            <h3>3.1 Service Providers</h3>
            <p>We may share your information with third-party service providers who assist us in operating and improving our services. These providers include:</p>
            <ul>
                <li><strong>Payment Processors:</strong> To securely process financial transactions and handle billing activities.</li>
                <li><strong>Data Hosting Providers:</strong> To store and manage data in secure, reliable environments.</li>
                <li><strong>Customer Support Providers:</strong> To offer support services and address customer inquiries.</li>
                <li><strong>Analytics Providers:</strong> To analyze usage data and generate insights that help us improve our services.</li>
            </ul>
            <p>Service providers are contractually obligated to protect your information and use it only for the purposes for which it was shared. They are required to implement appropriate security measures and comply with applicable data protection laws.</p>

            <h3>3.2 Legal Compliance</h3>
            <p>We may disclose your information in the following circumstances:</p>
            <ul>
                <li><strong>Legal Obligations:</strong> When required to do so by law, regulation, or legal process. This includes complying with court orders, responding to subpoenas, and fulfilling legal or regulatory requirements.</li>
                <li><strong>Enforcement of Rights:</strong> To enforce our terms of service, policies, or agreements, and to protect our rights and interests, as well as those of our users and third parties.</li>
                <li><strong>Protection of Others:</strong> To protect the rights, property, or safety of Scan2Hire, our users, or others. This may include responding to emergencies, investigating fraud, or addressing security threats.</li>
            </ul>

            <h3>3.3 Business Transactions</h3>
            <p>In the event of a merger, acquisition, or other significant business transaction, your information may be transferred as part of the transaction. We will notify you of such changes and update this Privacy Policy accordingly.</p>

            <h2>4. Data Security</h2>
            <p>We implement a variety of security measures to protect your personal information from unauthorized access, disclosure, or alteration. These measures include:</p>
            <ul>
                <li><strong>Encryption:</strong> To protect data transmitted between your device and our servers using industry-standard encryption protocols.</li>
                <li><strong>Access Controls:</strong> To restrict access to personal information to authorized personnel only, based on their roles and responsibilities.</li>
                <li><strong>Secure Storage:</strong> To store data in secure, access-controlled environments with physical and technical safeguards.</li>
            </ul>
            <p>While we strive to protect your information, no security system is entirely foolproof. We cannot guarantee the absolute security of your data and encourage you to take precautions when sharing information online.</p>

            <h2>5. Your Rights and Choices</h2>

            <h3>5.1 Access and Correction</h3>
            <p>You have the right to access and update your personal information. You can review and modify your account details through your account settings or by contacting us directly. We will respond to your requests in a timely manner and make reasonable efforts to ensure that your information is accurate and up-to-date.</p>

            <h3>5.2 Data Deletion</h3>
            <p>You may request the deletion of your personal information by contacting us. We will process such requests in accordance with applicable laws and regulations. Please note that we may retain certain information as required by law or for legitimate business purposes, such as record-keeping and legal compliance.</p>

            <h3>5.3 Opt-Out</h3>
            <p>You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications. You may also adjust your preferences in your account settings to control the types of communications you receive from us.</p>

            <h2>6. Data Retention</h2>
            <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce our agreements.</p>

            <h2>7. International Data Transfers</h2>
            <p>Your information may be transferred to, stored, or processed in countries outside of your country of residence, including the United States. These countries may have different data protection laws than those in your country. By using our services, you consent to the transfer of your information to these countries and acknowledge that your information will be subject to the data protection laws of those jurisdictions.</p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically to reflect changes in our practices, legal requirements, or industry standards. Any updates will be posted on this page with an updated effective date. We encourage you to review this policy regularly to stay informed about how we protect your information.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:</p>
            <p>Call: <a href="tel:+91-9313834815">+91-9313834815</a></p>
            <p>Email: <a href="mailto:connect@scan2hire.com">connect@scan2hire.com</a></p>

            <p>Thank you for trusting Scan2Hire with your information. We are committed to protecting your privacy and ensuring a secure and positive experience with our services.</p>
        </section>
    )
}

export default PrivacyPolicy;