import { useEffect } from "react";
import { useTheme } from "./contexts/ThemeContext";
import Titlebar from "./sections/Titlebar";
import HeroSection from "./sections/HeroSection";
import HIWSection from "./sections/HIWSection";
import FeaturesSection from "./sections/FeaturesSection";
import PricingSection from "./sections/PricingSection";
import TestimonialsSection from "./sections/TestimonialsSection";
import FAQsSection from "./sections/FAQsSection";
import ContactUsSection from "./sections/ContactUsSection";
import Footer from "./sections/Footer";
import PrivacyPolicy from "./sections/PrivacyPolicy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsAndConditions from "./sections/TermsAndConditions";

function App() {
	const { theme } = useTheme();	

	useEffect(() => {
		document.documentElement.setAttribute('data-theme', theme);
	}, [theme]);

  return (
	<Router>
		<div className="App">
			<Titlebar />
				<Routes>
					<Route index path="/" element={
						<>
							<HeroSection />
							<HIWSection />
							<FeaturesSection />
							<PricingSection />
							<TestimonialsSection />
							<FAQsSection />
							<ContactUsSection />
						</>
					} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
				</Routes>
			<Footer />
		</div>
	</Router>
  );
}

export default App;
