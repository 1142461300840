import { IconButton } from "@mui/material";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
import TwitterIcon from "../icons/TwitterIcon";
import "../styles/footer.css";
import { useTheme } from "../contexts/ThemeContext";
import { Link } from "react-router-dom";

const COMPANY_URL = "https://www.lemolite.com"
const Links = [
    {
        url : "https://www.linkedin.com/company/scan2hire/",
        icon : LinkedInIcon,
    },
    {
        url : "https://www.facebook.com/profile.php?id=61563069183287",
        icon : FacebookIcon,
    },
    {
        url : "https://www.instagram.com/scan2hire/",
        icon : InstagramIcon,
    },
    {
        url : "https://x.com/scan2hire",
        icon : TwitterIcon,
    }
]

const Footer = ()=>{
    const { theme } = useTheme();
    return (
        <footer className={`footer-section ${theme}`}>
            <div className="social-media">
                <h3>Follow Us</h3>
                <div className="sm-icons">
                    {
                        Links.map((linkItem, index)=>(
                            <a key={index} href={linkItem.url} target="blank">
                                <linkItem.icon />
                            </a>
                        ))
                    }
                </div>
                <div className="footer-bar"></div>
                <div className="footer-info">
                    <p>Powered by <Link target="blank" to={COMPANY_URL}>Lemolite Technologies LLP</Link></p>
                    <p><Link to="/terms-and-conditions">Terms & Conditions</Link> <span className="footer-vbar">|</span> <Link to={"/privacy-policy"}>Privacy Policy</Link></p>
                    <p>©Scan2Hire 2024 All rights reserved</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;