const DarkThemeIcon = ({color="#EA723C", ...params})=>{
    return (
        <svg width="60" height="60" {...params} viewBox="0 0 60 60" fill="none" xmlns="http://www.w=3.org/2000/svg">
            <rect x="0.5" y="59.5" width="59" height="59" rx="29.5" transform="rotate(-90 0.5 59.5)" stroke={color}/>
            <path d="M30 23.5C33.5899 23.5 36.5 26.4101 36.5 30C36.5 33.5899 33.5899 36.5 30 36.5C26.4101 36.5 23.5 33.5899 23.5 30C23.5 26.4101 26.4101 23.5 30 23.5Z" fill={color} stroke={color}/>
            <path d="M17 30H18.95M41.05 30H43M39.1927 20.808L37.8138 22.1868M22.186 37.814L20.8072 39.1928M30 17V18.95M30 41.05V43M20.8073 20.8073L22.1862 22.1862M37.814 37.8133L39.1928 39.1922" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default DarkThemeIcon;