const EnhanceAccuracyIcon = ({color="#1C1C1C", ...params})=>{
    return (
        <svg width="54" height="54" {...params} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49 27C49 14.8497 39.1503 5 27 5C14.8497 5 5 14.8497 5 27C5 39.1503 14.8497 49 27 49C39.1503 49 49 39.1503 49 27Z" stroke={color} strokeWidth="2.5"/>
            <path d="M19 24.9999L25.5 31.9999L37 20.9999" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27 2V12" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
            <path d="M52 27L42 27" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
            <path d="M27 42V52" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
            <path d="M12 27L2 27" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
        </svg>
    )
}

export default EnhanceAccuracyIcon;