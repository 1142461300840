const DataProtectionIcon = ({color, ...params}) => {
    return (
        <svg width="72" height="72" {...params} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="72" height="72" rx="15" fill="#EA723C"/>
        <path d="M37.0233 28.6744C22.8605 28.6744 17 26.4209 17 23.4C17 20.3791 22.3721 16.9535 36.0465 17.9302" stroke="white" strokeWidth="1.86"/>
        <path d="M48.2558 36.6837C48.2558 40.1362 41.259 42.9349 32.6279 42.9349C23.9969 42.9349 17 40.1362 17 36.6837" stroke="white" strokeWidth="1.86"/>
        <path d="M48.2558 35.0233V51.2084C48.2558 54.407 41.259 57 32.6279 57C23.9969 57 17 54.407 17 51.2084V24.1814" stroke="white" strokeWidth="1.86"/>
        <path d="M24.8135 28.8698V33.5582" stroke="white" strokeWidth="1.86" strokeLinecap="round"/>
        <path d="M24.8135 42.9349V47.6232" stroke="white" strokeWidth="1.86" strokeLinecap="round"/>
        <path d="M46.3008 15C43.5175 15 41.7129 16.7748 39.5787 17.4218C38.7109 17.6848 38.2769 17.8163 38.1013 18.0017C37.9257 18.1871 37.8743 18.458 37.7715 18.9999C36.671 24.7981 39.0764 30.1587 44.8129 32.2451C45.4293 32.4693 45.7374 32.5814 46.3038 32.5814C46.8701 32.5814 47.1783 32.4693 47.7946 32.2451C53.5308 30.1586 55.9339 24.7981 54.8331 18.9999C54.7302 18.4579 54.6787 18.187 54.5031 18.0016C54.3275 17.8162 53.8936 17.6847 53.0258 17.4218C50.8907 16.775 49.0841 15 46.3008 15Z" fill="#EA723C" stroke="white" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default DataProtectionIcon;