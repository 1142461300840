const ResumeAnalysisIcon = ({color, ...params})=>{
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="72" height="72" rx="15" fill="#EA723C"/>
        <path d="M54.8 57L50.2912 52.4912M50.2912 52.4912C50.5317 52.2506 50.7553 51.9932 50.96 51.7207C51.8642 50.5172 52.4 49.0212 52.4 47.4C52.4 43.4235 49.1765 40.2 45.2 40.2C41.2235 40.2 38 43.4235 38 47.4C38 51.3764 41.2235 54.6 45.2 54.6C47.1882 54.6 48.9882 53.7941 50.2912 52.4912Z" stroke="white" strokeWidth="1.8" strokeLinecap="round"/>
        <path d="M33.8002 57H33.2275C26.3789 57 22.9547 57 20.5766 55.3245C19.8953 54.8445 19.2904 54.2752 18.7804 53.6339C17.0002 51.3958 17.0002 48.173 17.0002 41.7273V36.3818C17.0002 30.1592 17.0002 27.0478 17.985 24.5629C19.5681 20.568 22.9162 17.4169 27.1608 15.9268C29.801 15 33.1068 15 39.7184 15C43.4964 15 45.3854 15 46.8942 15.5296C49.3196 16.3811 51.2328 18.1817 52.1375 20.4645C52.7002 21.8845 52.7002 23.6624 52.7002 27.2182V36" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 36C17 32.134 20.134 29 24 29C25.3981 29 27.0465 29.245 28.4059 28.8807C29.6137 28.5571 30.5571 27.6137 30.8807 26.4059C31.245 25.0465 31 23.3981 31 22C31 18.134 34.134 15 38 15" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <line x1="24.1998" y1="36.9" x2="24.1998" y2="47.7" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <line x1="34.6998" y1="32.7" x2="34.6998" y2="47.7" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <line x1="45.1998" y1="22.2" x2="45.1998" y2="39.3" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ResumeAnalysisIcon;