import { useEffect, useRef, useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import SectionTitle from "../components/SectionTitle";
import "../styles/testimonials-section.css";
import QuoteIcon from "../icons/QuoteIcon";
import { animate, motion, useMotionValue, wrap } from "framer-motion";
import useMeasure from "react-use-measure";

const Testimonials = [
    {
        image : "image1.png",
        name : "Sarah T.",
        desc : "HR Manager",
        testimony : "The AI matching feature is outstanding. We’re consistently finding top-tier candidates, and our retention rates have improved significantly."
    },
    {
        image : "image2.jpeg",
        name : "Sam B.",
        desc : "Head of Recruitment",
        testimony : "Setting up Scan2Hire with our current system was super easy, and the support team was awesome."
    },
    {
        image : "image3.jpeg",
        name : "Jenish L.",
        desc : "Chief Human Resource Officer",
        testimony : "Scan2Hire has completely transformed our hiring process. It's faster, more accurate, and has significantly reduced the time we spend on hiring."
    }
]

const TestimonialsSection = ()=>{
    const { theme } = useTheme();
    const [cards, setCards] = useState(Testimonials);
    const [wrapperRef, {width:wrapperWidth}] = useMeasure();
    const [cardRef, {width:cardWidth}] = useMeasure();
    const xTranslation = useMotionValue(0);

    useEffect(()=>{
        let finalPosition = -3*cardWidth - (0.09 * wrapperWidth);
        if(window.innerWidth > 600 && window.innerWidth < 1200){
            finalPosition = -3*cardWidth - (0.15 * wrapperWidth);
        }
        
        const control = animate(xTranslation, [0, finalPosition], {
            ease:"linear",
            duration:25,
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 0
        })

        return control.stop
    }, [xTranslation, cardWidth, wrapperWidth])

    return (
        <section className="testimonials-section" id="testimonials-section">
            <SectionTitle title={"Voices of Satisfaction"} />
            <div className={`testimonials ${theme}`}>
                <motion.div className="testimonials-animation-wrapper">
                    <motion.div
                        ref={wrapperRef}
                        style={{
                            x:xTranslation
                        }}
                        className="testimonials-animation-wrapper-inner">
                        { 
                            [...cards, ...cards].map((card, index)=>(
                                <motion.div key={index} ref={cardRef} className="testimonials-card">
                                    <QuoteIcon className="testimonials-quote" />
                                    <div className="testimony">"{card.testimony}"</div>
                                    <div className="testimony-source">
                                        <img src={card.image} alt="" className="ts-img" />
                                        <div className="ts-person">
                                            <div className="ts-person-name">{card.name}</div>
                                            <div className="ts-person-desc">{card.desc}</div>
                                        </div>
                                    </div>
                                </motion.div>
                            ))
                        }
                    </motion.div>
                </motion.div>
            </div>
        </section>
    )
}

export default TestimonialsSection;