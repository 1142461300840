const StarIcon = ({color, ...params}) => {
    return (
        <svg width="51" height="51" {...params} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5 0.5L27.4445 23.5555L50.5 25.5L27.4445 27.4445L25.5 50.5L23.5555 27.4445L0.5 25.5L23.5555 23.5555L25.5 0.5Z" fill="url(#paint0_radial_234_3283)"/>
        <defs>
        <radialGradient id="paint0_radial_234_3283" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.5 25.5) rotate(90) scale(25)">
        <stop stopColor="#C44911"/>
        <stop offset="0.549" stopColor="#EA723C"/>
        <stop offset="1" stopColor="#808080"/>
        </radialGradient>
        </defs>
        </svg>
    )
}

export default StarIcon;