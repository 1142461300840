import { useLocation, useNavigate } from "react-router-dom";

const TitlebarItem = ({value, targetId=null, activeSection=null, setActiveSection=()=>{}})=>{
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = ()=>{
        if(targetId === null) return;
        setActiveSection(targetId);
        if(location.pathname !== '/'){
            navigate('/', {state : {targetId : targetId}});
            return;
        }
        document.getElementById(targetId).scrollIntoView({behavior:"smooth"});
    }
    return (
        <div className={"titlebar-item" + ((activeSection === targetId) ? " selected" : "")} onClick={handleClick}>
            <p>{value}</p>
            <p className="ti-bar"></p>
        </div>
    )
}

export default TitlebarItem;