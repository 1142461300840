import { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import { useTheme } from "../contexts/ThemeContext";
import "../styles/pricing-section.css";

const Plans = [
    {
        name : "Monthly",
        basePrice : '2,400',
        addon : '1,400' 
    },
    {
        name : "Quarterly",
        basePrice : '4,800',
        addon : '2,880' 
    },
    {
        name : "Semi-annually",
        basePrice : '9,000',
        addon : '5,400' 
    },
    {
        name : "Yearly",
        basePrice : '16,000',
        addon : '9,600' 
    },
]

const PricingSection = ()=>{
    const { theme } = useTheme();
    const [plan, setPlan] = useState('m');

    return (
        <section className="pricing-section" id="pricing-section">
            <SectionTitle title={"Get Ongoing Access with Easy Payments!"} />
            <div className="pricing-box">
                <h3 className="pricing-demo">Get <strong>15 Days</strong> Free Trial!</h3>
                <div className={`pricing-chip ${theme}`}>
                    <div className={`pricing-chip-option ${plan === 'm' ? 'active' : null}`}
                        onClick={()=>setPlan('m')}
                    >Monthly</div>
                    <div className={`pricing-chip-option ${plan === 'q' ? 'active' : null}`}
                        onClick={()=>setPlan('q')}
                    >Quarterly</div>
                    <div className={`pricing-chip-option ${plan === 's' ? 'active' : null}`}
                        onClick={()=>setPlan('s')}
                    >Semiannually</div>
                    <div className={`pricing-chip-option ${plan === 'y' ? 'active' : null}`}
                        onClick={()=>setPlan('y')}
                    >Yearly </div>
                </div>

                <table className={`pricing-plans ${theme}`}>
                    <thead>
                        <tr>
                            <td>Features</td>
                            <td>Price</td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td><strong>Base Price</strong> (Single User)</td>
                            <td><strong>&#8377;{Plans.filter(item=>item.name.toLowerCase().startsWith(plan))[0].basePrice}</strong></td>
                        </tr>
                        <tr>
                            <td><div><strong>Each Additional User</strong> <div className={`pricing-chip-suboption ${theme}`}>60% off</div></div></td>
                            <td><strong>&#8377;{Plans.filter(item=>item.name.toLowerCase().startsWith(plan))[0].addon}</strong>/User</td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colSpan={2}>
                                <div>
                                    <div className="pricing-button" onClick={()=>{
                                        document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                                    }}>Get Started</div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <h3 className="pricing-demo">One-Time Setup Fee: <strong>₹5,000</strong> (No extra charges in future)</h3>

                {/* <div className="pricing-plans">
                    {
                        Plans.map((item, index)=>{
                            return <div key={index} className={`pricing-plans-card ${theme}`}>
                                <h3 className="pricing-card-name">{item.name}</h3>
                                <div className="pricing-card-price">${item.pricing[plan === 'm' ? 'monthly' : 'yearly']}<span>/month</span></div>
                                {item.features.map((ftr, idx)=>(
                                    <div key={idx} className="pricing-card-feature">{ftr}</div>
                                ))}
                                <div className="pricing-button" onClick={()=>{
                                    document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                                }}>Get Started</div>
                            </div>
                        })
                    }
                </div> */}
            </div>
        </section>
    )
}

export default PricingSection;